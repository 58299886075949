@font-face {
  font-family: "Pretendard";
  src: url("styles/fonts/PretendardVariable.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: "Pretendard";
  background-color: #ffffff;
}
